<template>
  <div class="data-box">
    <van-nav-bar
        :title="activeInfo.actName"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-label">对接人</span>
          <span class="span-value" style="width: 60%;">{{ activeInfo.name ? activeInfo.name : "无" }}</span>
          <a :href="'tel:' + activeInfo.phone" class="iconfont icon-dianhua1" v-show="activeInfo.phone"></a>
        </div>
        <div class="body-div-item">
          <span class="span-label">对接车辆</span>
          <span class="span-value">{{ activeInfo.plate ? activeInfo.plate : "无" }}</span>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in personKeyValueList" :key="index">
          <span class="span-label">{{ item.key }}</span>
          <span class="span-value" :style="{width: item.hasIcon ? '60%' : '',}">{{ item.value ? item.value : "无" }}</span>
          <a :href="'tel:' + item.phone" class="iconfont icon-dianhua1" v-show="item.hasIcon&&item.phone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in checkKeyValueList" :key="index" @click="toCheckItem(item)" v-show="item.value">
          <span class="span-label">{{ item.key }}</span>
          <span class="span-value" style="text-align: right;">{{ item.value }}</span>
          <i class="iconfont icon-iconfontjiantou2" style="color: #3E4576;"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orgCheck_details",
  data() {
    return {
      activeInfo: {
        name: "",
        phone: "",
        plate: ""
      },
      personKeyValueList: [
        { key: "归属单位", value: "", hasIcon: false},
        { key: "签到日期", value: "", hasIcon: false},
        { key: "签退日期", value: "", hasIcon: false},
        { key: "参与日期", value: "", hasIcon: false},
        { key: "助理人员", value: "", hasIcon: true, phone: ""}
      ],
      checkKeyValueList: [
        { key: "人员对接", value: "", router: "orgCheck_person"},
        { key: "车辆对接", value: "", router: "orgCheck_car"},
        { key: "住宿详情", value: "", router: "orgCheck_live"},
        { key: "用餐详情", value: "", router: "orgCheck_meals"},
        { key: "物品发放", value: "", router: "orgCheck_material"}
      ]
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let params = that.$route.params;
      if(Object.keys(params).length > 0){
        that.$store.commit("setSelectOPDetails", params);
      }else{
        params = that.$store.getters.getSelectOPDetails;
      }
      that.activeInfo.name = params.designation;
      that.activeInfo.actName = params.activityName;
      that.activeInfo.phone = params.participantNumWorkPhone;
      that.activeInfo.plate = params.carPlate;
      that.personKeyValueList[0].value = params.postName;
      that.personKeyValueList[1].value = params.takeStartDate;
      that.personKeyValueList[2].value = params.takeEndDate;
      that.personKeyValueList[3].value = params.actStartDate ? (params.actStartDate + " - " + params.actEndDate) : "";
      params.activitySets.forEach(item => {
        if(item.relateType === 0){
          that.checkKeyValueList[0].value = item.statusSendId === 0 ? "待进行" : (item.statusSendId === 1 ? "已签到" : "已签退");
        }
        if(item.relateType === 1){
          that.checkKeyValueList[1].value = item.statusSendId === 0 ? "待进行" : (item.statusSendId === 1 ? "已签到" : "已签退");
        }
        if(item.relateType === 2){
          that.checkKeyValueList[2].value = item.statusSendId === 0 ? "待入住" : (item.statusSendId === 1 ? "已入住" : "已退房");
        }
        if(item.relateType === 3){
          that.checkKeyValueList[3].value = item.statusSendId === 0 ? "待安排" : (item.statusSendId === 1 ? "已安排" : "已用餐");
        }
        if(item.relateType === 4){
          that.checkKeyValueList[4].value = item.statusSendId === 0 ? "待发放" : (item.statusSendId === 1 ? "已领取" : "已使用");
        }
        if(item.relateType === 8){
          that.personKeyValueList[4].value = item.chargeName;
          that.personKeyValueList[4].phone = item.chargePhone;
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toCheckItem(item){
      let that = this;
      that.$router.push(item.router);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>